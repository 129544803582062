import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./AddCompany.css";
import BackArrow from "../../../assets/images/back-arrow.svg";
import eyeSlashIcon from "../../../assets/images/slash-eye-icon.svg";
import eyeIcon from "../../../assets/images/eye.svg";
import Service from "../../../shared/_services/inventory.service";
import { confirm } from "react-confirm-box";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as Yup from "yup";
import InventoryService from "../../../shared/_services/inventory.service";

const AddCompany = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(eyeSlashIcon);
  const [canoID, setCanoID] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const Navigate = useNavigate();

  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  useEffect(() => {
    const fetchCanoID = async () => {
      try {
        // Make an API call to get the CanoID
        const response = await InventoryService.getCanoID();
        if (response) {
          setCanoID(response[0]?.id);
        }
      } catch (error) {
        console.error("Error fetching CanoID:", error);
      }
    };

    fetchCanoID();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setPasswordIcon(showPassword ? eyeSlashIcon : eyeIcon);
  };

  const initialValues = {
    id: canoID,
    CompanyName: "",
    CompanyCode: "",
    databaseName: "",
    url: "",
    AuthID: "",
    databaseURL: "",
    databasePassword: "",
    databaseUser: "",
    SAPURL: "",
    SAPClientID: "",
    SAPRefreshToken: "",
    SAPURL2: "",
    SAPClientSecret: "",
    TypeOfProduct: "",
    PricePerWorker: "",
    PricePerInventoryStaff: "",
    PricePerHybridUser: "",
    isTrial: false,
    TrialDays: 0,
    JoiningDate: formattedDate,
    StripeCustomerID: "",
    StripePaymentMethodID: "",
    Disabled: false,
    AllowMultiCompJobReq: false,
    StripePaymentStatus: "",
    StripePaymentCharged : false
  };

  const validationSchema = Yup.object().shape({
    CompanyName: Yup.string().required("Company Name is required"),
    CompanyCode: Yup.string().required("Company Code is required"),
    databaseName: Yup.string().required("Database Name is required"),
    url: Yup.string()
      .required("URL is required")
      .matches(
        /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
        "Invalid URL format. Please enter a valid URL."
      ),
    AuthID: Yup.string().required("Auth ID is required"),
    databaseURL: Yup.string().required("Database URL is required"),
    databasePassword: Yup.string().required("Database Password is required"),
    databaseUser: Yup.string().required("Database User is required"),
    SAPURL: Yup.string(),
    SAPClientID: Yup.string(),
    SAPRefreshToken: Yup.string(),
    SAPURL2: Yup.string(),
    SAPClientSecret: Yup.string(),
    TypeOfProduct: Yup.string().required("Product Type is required"),
    PricePerWorker: Yup.number()
      .positive("Price per Worker should be positive")
      .required("Price per Worker is required"),
    PricePerInventoryStaff: Yup.number()
      .positive("Price per Inventory Staff should be positive")
      .required("Price per Inventory Staff is required"),
    PricePerHybridUser: Yup.number()
      .positive("Price per Hybrid User should be positive")
      .required("Price per Hybrid User is required"),

    isTrial: Yup.boolean(),

    TrialDays: Yup.number().test(
      "isTrial",
      "Trial Days must be between 1 and 365",
      function (value) {
        if (formik.values.isTrial) {
          return value !== undefined && value > 0 && value <= 365;
        }

        // If isTrial is not true, validation passes
        return true;
      }
    ),
  });
  // Program data update button

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const result = await confirm(
        "Do you want to add a new Cano Account?",
        options
      );

      if (result) {
        let { isTrial, TrialDays, ...rest } = values;

        if (!isTrial) {
          TrialDays = 0;
        }

        const data = {
          isTrial,
          TrialDays,
          ...rest,
          CanoID: canoID,
          AllowMultiCompJobReq: isChecked,
        };

        
        // Save Stripe user
        Service.saveStripeUser(data.CompanyName, data.databaseUser)
          .then((response) => {
            if (response && response.status === "failure") {
              let variant = "error";
              enqueueSnackbar(response.message, { variant });
            } else if (response && response.status === "success") {
              const stripeCustomerId = response.message.id;
              console.log("Stripe user has been saved");

              // Proceed to add Cano account only if stripeCustomerId is available
              if (stripeCustomerId) {
                Service.addNewCompany(
                  data,
                  enqueueSnackbar,
                  stripeCustomerId
                ).then((response) => {
                  if (response.data && typeof response.data === "string") {
                    let variant = "error";
                    enqueueSnackbar(response?.data.message.status, {
                      variant,
                    });
                  } else if (
                    response.data &&
                    response.data.status === "success"
                  ) {
                    let variant = "success";
                    enqueueSnackbar(response?.data.message.status, {
                      variant,
                    });
                    Navigate("/company");
                  } else {
                    let variant = "error";
                    // enqueueSnackbar(response?.message, {
                    enqueueSnackbar(
                      "Error occurred while saving Cano Account.",
                      {
                        variant,
                      }
                    );
                  }
                });
              }
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            // Handle errors from saveStripeUser if any
          });
      }
    },
  });

  const options = {
    render: (message, onConfirm, onCancel) => {
      return (
        <div className="conform_modal">
          <h3>Are you Sure?</h3>
          <p> {message} </p>
          <div className="conform_box_btn">
            <button className="cancel_btn" onClick={onCancel}>
              Cancel
            </button>
            <button className="conform_btn" onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </div>
      );
    },
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <div className="page-heading d-flex">
            <Link to="/company">
              <img src={BackArrow} alt="back arrow" />
            </Link>
            <h4>New Cano Account</h4>
          </div>
        </Col>
      </Row>
      <Form onSubmit={formik.handleSubmit}>
        <div className="white-box-sec mt-3">
          <Row>
            <Col lg={6}>
              <Form.Group className="pb-2">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  name="CompanyName"
                  {...formik.getFieldProps("CompanyName")}
                />
                {formik.touched.CompanyName && formik.errors.CompanyName ? (
                  <div className="error-msg">{formik.errors.CompanyName}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="pb-2">
                <Form.Label> Company Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company code"
                  name="CompanyCode"
                  {...formik.getFieldProps("CompanyCode")}
                />
                {formik.touched.CompanyCode && formik.errors.CompanyCode ? (
                  <div className="error-msg">{formik.errors.CompanyCode}</div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter URL"
                  name="url"
                  {...formik.getFieldProps("url")}
                />
                {formik.touched.url && formik.errors.url ? (
                  <div className="error-msg">{formik.errors.url}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Auth ID</Form.Label>
                <Form.Control
                  type="text"
                  name="AuthID"
                  placeholder="Enter Auth ID"
                  {...formik.getFieldProps("AuthID")}
                />
                {formik.touched.AuthID && formik.errors.AuthID ? (
                  <div className="error-msg">{formik.errors.AuthID}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Product Type</Form.Label>
                <Form.Control
                  as="select"
                  name="TypeOfProduct"
                  className="sel-product-type"
                  {...formik.getFieldProps("TypeOfProduct")}
                >
                  <option>Select Product Type</option>
                  <option>Inventory</option>
                  <option>Mobile</option>
                  <option>Hybrid</option>
                </Form.Control>
                {formik.touched.TypeOfProduct && formik.errors.TypeOfProduct ? (
                  <div className="error-msg">{formik.errors.TypeOfProduct}</div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Database URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter database URL"
                  name="databaseURL"
                  {...formik.getFieldProps("databaseURL")}
                />
                {formik.touched.databaseURL && formik.errors.databaseURL ? (
                  <div className="error-msg">{formik.errors.databaseURL}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-1">
                <Form.Label>Database Password</Form.Label>
                <div className="eye-icon">
                  <img
                    src={passwordIcon}
                    className="hide-pass"
                    alt="eye-icon"
                    onClick={togglePasswordVisibility}
                  />
                </div>
                <Form.Control
                  placeholder="SQL password"
                  id="exampleInputPassword1"
                  type={showPassword ? "text" : "password"}
                  name="databasePassword"
                  {...formik.getFieldProps("databasePassword")}
                />
                {formik.touched.databasePassword &&
                formik.errors.databasePassword ? (
                  <div className="error-msg">
                    {formik.errors.databasePassword}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Database Name</Form.Label>
                <Form.Control
                  type="text"
                  name="databaseName"
                  placeholder="Enter database name"
                  {...formik.getFieldProps("databaseName")}
                />
                {formik.touched.databaseName && formik.errors.databaseName ? (
                  <div className="error-msg">{formik.errors.databaseName}</div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Database User</Form.Label>
                <Form.Control
                  type="text"
                  name="databaseUser"
                  placeholder="Enter database user"
                  {...formik.getFieldProps("databaseUser")}
                />
                {formik.touched.databaseUser && formik.errors.databaseUser ? (
                  <div className="error-msg">{formik.errors.databaseUser}</div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="white-box-sec mt-3">
          <Row>
            <Col>
              <h6 className="set-price">SAP </h6>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>SAP URL</Form.Label>
                <Form.Control
                  type="text"
                  name="SAPURL"
                  placeholder="Enter SAP URL"
                  {...formik.getFieldProps("SAPURL")}
                />
                {formik.touched.SAPURL && formik.errors.SAPURL ? (
                  <div className="error-msg">{formik.errors.SAPURL}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label> SAP Client ID</Form.Label>
                <Form.Control
                  type="text"
                  name="SAPClientID"
                  placeholder="Enter  SAP Client ID"
                  {...formik.getFieldProps("SAPClientID")}
                />
                {formik.touched.SAPClientID && formik.errors.SAPClientID ? (
                  <div className="error-msg">{formik.errors.SAPClientID}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>SAP Refresh Token</Form.Label>
                <Form.Control
                  type="text"
                  name="SAPRefreshToken"
                  placeholder="Enter SAP refresh token"
                  {...formik.getFieldProps("SAPRefreshToken")}
                />
                {formik.touched.SAPRefreshToken &&
                formik.errors.SAPRefreshToken ? (
                  <div className="error-msg">
                    {formik.errors.SAPRefreshToken}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>SAP URL2</Form.Label>
                <Form.Control
                  type="text"
                  name="SAPURL2"
                  placeholder="Enter SAP URL2"
                  {...formik.getFieldProps("SAPURL2")}
                />
                {formik.touched.SAPURL2 && formik.errors.SAPURL2 ? (
                  <div className="error-msg">{formik.errors.SAPURL2}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label> SAP Client Secret</Form.Label>
                <Form.Control
                  type="text"
                  name="SAPClientSecret"
                  placeholder="Enter  SAP client secret"
                  {...formik.getFieldProps("SAPClientSecret")}
                />
                {formik.touched.SAPClientSecret &&
                formik.errors.SAPClientSecret ? (
                  <div className="error-msg">
                    {formik.errors.SAPClientSecret}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="white-box-sec mt-3 add_company_setprice">
          <Row>
            <Col>
              <h6 className="set-price">Set Price</h6>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group className="pb-2 add_company_setprice">
                <Form.Label>Price per Worker</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Enter price per worker"
                    name="PricePerWorker"
                    // value={`$${formik.values.PricePerWorker}`}
                    {...formik.getFieldProps("PricePerWorker")}
                  />
                </div>
                {formik.touched.PricePerWorker &&
                formik.errors.PricePerWorker ? (
                  <div className="error-msg">
                    {formik.errors.PricePerWorker}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className="pb-2">
                <Form.Label>Price per Inventory Staff</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Enter price per inventory staff"
                    name="PricePerInventoryStaff"
                    {...formik.getFieldProps("PricePerInventoryStaff")}
                  />
                </div>
                {formik.touched.PricePerInventoryStaff &&
                formik.errors.PricePerInventoryStaff ? (
                  <div className="error-msg">
                    {formik.errors.PricePerInventoryStaff}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className="pb-2">
                <Form.Label>Price per Hybrid User</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Enter price per hybrid user"
                    name="PricePerHybridUser"
                    {...formik.getFieldProps("PricePerHybridUser")}
                  />
                </div>
                {formik.touched.PricePerHybridUser &&
                formik.errors.PricePerHybridUser ? (
                  <div className="error-msg">
                    {formik.errors.PricePerHybridUser}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className="pb-2">
                <Form.Label>Price per Field</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Enter price per field"
                    name="PricePerHybridUser"
                    {...formik.getFieldProps("PricePerHybridUser")}
                  />
                </div>
                {formik.touched.PricePerHybridUser &&
                formik.errors.PricePerHybridUser ? (
                  <div className="error-msg">
                    {formik.errors.PricePerHybridUser}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="white-box-sec mt-3">
          <Row>
            <Col lg={4}>
              <div className="d-flex mt-4">
                <h6 className="mr-2">Trial</h6>
                <Toggle
                  icons={{
                    checked: (
                      <div style={{ display: "flex" }}>
                        <i className="fas fa-check-circle"></i>
                      </div>
                    ),
                    unchecked: (
                      <div style={{ display: "flex" }}>
                        <i className="fas fa-times-circle"></i>
                      </div>
                    ),
                  }}
                  {...formik.getFieldProps("isTrial")}
                />
              </div>
            </Col>
            {formik.values.isTrial && (
              <Col lg={4}>
                <Form.Group className="pb-2">
                  <Form.Label>Days</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter No of Days"
                    name="TrialDays"
                    {...formik.getFieldProps("TrialDays")}
                  />
                  {formik.touched.TrialDays && formik.errors.TrialDays ? (
                    <div className="error-msg">{formik.errors.TrialDays}</div>
                  ) : null}
                </Form.Group>
              </Col>
            )}
            <Col lg={4}>
              <Form.Group className="d-flex mt-4">
                <Form.Label>Allow Multi Company Job Requisition</Form.Label>
                <Form.Check
                  type="switch" // You can use 'checkbox' here for a checkbox instead
                  id="isActiveSwitch" // Unique ID for the input
                  label=""
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="button-footer new-comp my-5">
          <Row>
            <Col lg={6} className="col-6">
              <div className="cancel-btn">
                <Link to="/company">
                  <Button>Cancel</Button>
                </Link>
              </div>
            </Col>
            <Col lg={6} className="col-6">
              <div className="submit-btn float-right">
                <Button
                  type="submit"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </Container>
  );
};

export default AddCompany;
